import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(_, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/userList', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/userById', { params: { id }})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(_, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/userCreate', { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(_, user) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/userUpdate', { ...user })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(_, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/userDelete', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setPassword(_, { id, email }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/mailSetPassword', { email, link: `https://akara-cms-frontend-oyfta6dtla-as.a.run.app/register?id=${id}` })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPassword(_, { id, email }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/mailResetPassword', { email, link: `https://akara-cms-frontend-oyfta6dtla-as.a.run.app/reset-password?id=${id}` })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
