var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-firstname"}},[_vm._v("First name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-firstname","placeholder":"First name","name":"firstname"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-lastname"}},[_vm._v("Last name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-lastname","placeholder":"Last name","name":"lastname"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-email"}},[_vm._v("Email "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-email","placeholder":"Email","name":"email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-phone"}},[_vm._v("Mobile phone "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"mobile phone","rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-phone","placeholder":"Mobile phone","name":"phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-status"}},[_vm._v("Status "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"account-status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-role"}},[_vm._v("Role "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"disabled":true,"input-id":"account-role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-75",attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"mb-50",attrs:{"show":"","variant":"warning"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Invitation an account ")]),_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v("System will send link to verify and setting password via email for login to use the content management system.")])])])],1)],1)],1)],1)],1),_c('div',{staticClass:"fixed-footer justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary","to":{ name: 'user-list'}}},[_c('span',[_vm._v("Cancel")])]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success","type":"submit","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',[_vm._v("Add User")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }