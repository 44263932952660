import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserCreate() {
  const toast = useToast()
  const roleOptions = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Author', value: 'author' },
    { label: 'Editor', value: 'editor' },
    { label: 'Maintainer', value: 'maintainer' },
    { label: 'Subscriber', value: 'subscriber' },
  ]
  const statusOptions = [
    { label: 'Pending', value: 'Pending' },
  ]
  
  const addUser = async (user) => {
    try {
      const response = await store.dispatch('app-user/addUser', user);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error add user.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }
  
  const setPassword = async (id, email) => {
    try {
      const response = await store.dispatch('app-user/setPassword', { id, email });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error send email to user.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    addUser,
    setPassword,
    roleOptions,
    statusOptions,
  }
}
