<template>
  <div>
    <b-card>
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="account-firstname">First name <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="first name"
                  rules="required"
                >
                  <b-form-input
                    v-model="firstname"
                    :state="errors.length > 0 ? false:null"
                    id="account-firstname"
                    placeholder="First name"
                    name="firstname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="account-lastname">Last name <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="last name"
                  rules="required"
                >
                  <b-form-input
                    v-model="lastname"
                    :state="errors.length > 0 ? false:null"
                    id="account-lastname"
                    placeholder="Last name"
                    name="lastname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="account-email">Email <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="email address"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="email"
                    :state="errors.length > 0 ? false:null"
                    id="account-email"
                    placeholder="Email"
                    name="email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="account-phone">Mobile phone <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="mobile phone"
                  rules="required|regex:^([0-9]+)$"
                >
                  <b-form-input
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    id="account-phone"
                    placeholder="Mobile phone"
                    name="phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="account-status">Status <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <v-select
                    v-model="status"
                    :state="errors.length > 0 ? false:null"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="account-status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="account-role">Role <span class="text-red">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="role"
                  rules="required"
                >
                  <v-select
                    v-model="role"
                    :state="errors.length > 0 ? false:null"
                    :options="roleOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="true"
                    input-id="account-role"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Invitation an account
                </h4>
                <div class="alert-body">
                  <p>System will send link to verify and setting password via email for login to use the content management system.</p>
                </div>
              </b-alert>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <div class="fixed-footer justify-content-end">
      <b-button
        variant="outline-secondary"
        :to="{ name: 'user-list'}"
      >
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        :disabled="loading"
        @click.prevent="validationForm"
      >
        <span>Add User</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useUserCreate from './useUserCreate'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    vSelect,
  },
  data() {
    return {
      loading: false,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      status: 'Pending',
      role: 'admin',
      required,
      regex,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return
        try {
          this.loading = true;
          const { id, data, status } = await this.addUser({
            email: this.email,
            password: "pleasechange",
            firstname: this.firstname,
            lastname: this.lastname,
            tel: this.phone,
            role: this.role,
            status: this.status
          })
          if (status !== 200) {
            this.makeToast(false, data);
            this.loading = false;
            return;
          }

          // send email reset password to user
          const { status: emailStatus } = await this.setPassword(id, this.email);
          this.$router
            .push({ name: 'user-list' })
            .then(() => {
              if (emailStatus !== 200) {
                this.makeToast(false, 'Email can not be sent.');
              } else {
                this.makeToast(true);
              }
              this.loading = false;
            })
        } catch(error) {
          this.makeToast(false, 'Unexpected error occurred please contact adminstrator.');
        }
      })
    },
    makeToast(success, message) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Create succeeded!' : 'Create failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `User has been created “${this.firstname} ${this.lastname}”.` : message}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      addUser,
      setPassword,
      roleOptions,
      statusOptions,
    } = useUserCreate()

    return {
      toast,
      addUser,
      setPassword,
      roleOptions,
      statusOptions,
    }
  },
}
</script>
